<template>
  <div class="content-game-loading">
    <div class="m-auto text-center">
      <b-spinner label="Loading..." class="m-auto spinner-main"></b-spinner>
      <br />
      Please wait...
    </div>
  </div>
</template>

<script>
export default {
  async mounted() {
    let isInLine = await this.$liff.isInClient();
    let isMobileDevice = window.matchMedia("(any-pointer: coarse)").matches;
    let getOs = await this.$liff.getOS();

    if (!isInLine && isMobileDevice && getOs == "web") {
      setTimeout(() => {
        window.location.href = `line://app/${this.$liff_ID_Login}`;
      }, 1000);
    } else {
      const that = this;
      let redirect_Page = () => {
        let tID = setTimeout(function () {
          if (that.$route.query["liff.state"]) {
            return console.log("object");
          }
          if (that.$route.query.redirect) {
            if (that.$liff.isLoggedIn()) {
              return that.$router.replace(`${that.$route.query.redirect}`);
            }
            return that.$router.replace(
              `/profile?redirect=${that.$route.query.redirect}`
            );
          }
          that.$router.replace("/profile");
          window.clearTimeout(tID);
        }, 500);
      };
      redirect_Page();
    }
  }
};
</script>

<style>
.content-game-loading {
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
  background: white;
  z-index: 1;
  display: flex;
}
</style>
